import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_2/sub_3/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      leftColumn: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-2-3-1"}}) {
        body
      },
      modal2: allMdx(filter: {frontmatter: {language: {eq: "DE"}, title: {eq: "modal-2-3-1-2"}}}) {
        edges {
          node {
            frontmatter {
              title,
              name
            }
            body
          }
        }
      },
      modal3: allMdx(filter: {frontmatter: {language: {eq: "DE"}, title: {eq: "modal-2-3-1-3"}}}) {
        edges {
          node {
            frontmatter {
              title,
              name
            }
            body
          }
        }
      },
      modal4: allMdx(filter: {frontmatter: {language: {eq: "DE"}, title: {eq: "modal-2-3-1-4"}}}) {
        edges {
          node {
            frontmatter {
              title,
              name
            }
            body
          }
        }
      },
    }
  `);
  return (
    <Template query={query} cityName="Moskau" />
  );
};


export default Slide;
